import * as snowplowTracker from '@snowplow/browser-tracker'
import { DebuggerPlugin } from '@snowplow/browser-plugin-debugger'
import {
  LinkClickTrackingPlugin,
  enableLinkClickTracking,
  trackLinkClick,
} from '@snowplow/browser-plugin-link-click-tracking'
import { PerformanceTimingPlugin } from '@snowplow/browser-plugin-performance-timing'
import env from '@shared/env'
import { getCookie, setCookie } from '@shared/utils'

import User from '@shared/data/models/user'
import { datadogRum } from '@datadog/browser-rum'
import { datadogLogs } from '@datadog/browser-logs'

const getSnowplowTracker = Promise.all([
  new Promise((resolve) => {
    const handleCookieConsentAccepted = () => {
      document.removeEventListener(
        'cookieConsentAccepted',
        handleCookieConsentAccepted,
      )
      resolve()
    }
    document.addEventListener(
      'cookieConsentAccepted',
      handleCookieConsentAccepted,
    )
  }),
  new Promise((resolve) => {
    const handleTrackingAccepted = () => {
      document.removeEventListener('trackingAccepted', handleTrackingAccepted)
      resolve()
    }
    document.addEventListener('trackingAccepted', handleTrackingAccepted)
  }),
]).then(() => {
  const tracker = snowplowTracker.newTracker(
    'web-checkout-tracker',
    env.VITE_SNOWPLOW_COLLECTOR,
    {
      appId: env.VITE_SNOWPLOW_APP_ID,
      platform: 'web',
      eventMethod: 'post',
      cookieSecure: true,
      contexts: {
        webPage: true,
      },
      plugins: [LinkClickTrackingPlugin(), PerformanceTimingPlugin()],
    },
  )

  snowplowTracker.enableActivityTracking({
    minimumVisitLength: 10,
    heartbeatDelay: 10,
  })

  enableLinkClickTracking()

  // setup snowplow debugger on all but production builds
  // this allows to see what exactly gets tracked in the browsers js console
  if (!env.isProd) {
    snowplowTracker.addPlugin({ plugin: DebuggerPlugin() })
  }

  // Can not extend the module snowplowTracker,
  // since it's not configurable (throws runtime error);
  // That's why we use a Proxy object instead;
  // Object.assign(snowplowTracker, {
  // trackLinkClick,
  // getCookieName: tracker.getCookieName,
  // });

  return new Proxy(snowplowTracker, {
    get(target, prop) {
      if (prop === 'trackLinkClick') {
        return trackLinkClick
      }
      if (prop === 'getCookieName') {
        return tracker.getCookieName
      }

      return target[prop]
    },
  })
})

export default {
  async setDomainUserIdCookie(domainUserId) {
    if (typeof domainUserId !== 'string' || !domainUserId) {
      return
    }

    // @snowplow/browser-tracker/dist/index.umd.js
    // 2 years by default;
    const configVisitorCookieTimeout = 63072000
    const cookieName = (await getSnowplowTracker).getCookieName('id')
    let cookieValue = getCookie(cookieName)
    // If domainUserId has not been set previously then
    // we can not change/setup domainUserId,
    // since we don't know a proper sessionId.
    // sessionId is sent along the request and is
    // generated internally in Snowplow for each new session.
    // sessionId is stored inside of domainUserId cookie value;
    if (!cookieValue) {
      return
    }

    cookieValue = cookieValue.split('.')
    // domainUserId + '.' + createTs + '.' + visitCount + '.' + nowTs + '.' + lastVisitTs + '.' + sessionId;
    cookieValue[0] = domainUserId
    cookieValue = cookieValue.join('.')

    setCookie(cookieName, cookieValue, {
      path: '/',
      // from Snowplow implementation;
      expires: new Date(
        +new Date() + configVisitorCookieTimeout * 1000,
      ).toUTCString(),
      domain: window.location.hostname,
    })
  },

  setUser(user) {
    getSnowplowTracker.then((snowplow) => {
      snowplow.setUserId(user?.id?.toString() || null)
    })

    if (user?.guid != null) {
      datadogRum.setUser({ id: user?.guid })
      datadogLogs.setUser({ id: user?.guid })
    } else {
      datadogRum.clearUser()
      datadogLogs.clearUser()
    }
  },

  trackViewScreen(screenName, options) {
    this.trackKaiaEvent(
      {
        event_name: 'cb.lfcycl.view_screen',
        app_area: 'lifecycle',
        action: 'view',
        object_type: 'screen',
        source: 'web-checkout',
        screen_name: screenName,
      },
      options,
    )
  },

  /**
   * @param payload {Object}
   * @param payload.event_name {string} eg 'cb.onbrdg.submit_initial_assessment'
   * @param payload.app_area {string} eg 'onboarding'
   * @param payload.action {string} eg 'submit'
   * @param payload.object_type {string} eg 'initial_assessment'
   * @param payload.source {string} eg 'client_browser'
   * @param payload.screen_name {string} eg 'onb_finish_congratulations'
   * @param [options] {Object} example:
   * {
   *   custom_payload: { ... free formatted JS object }
   * }
   */
  trackKaiaEvent(payload, options) {
    payload = Object(payload) === payload ? payload : {}
    options = Object(options) === options ? options : {}

    if (typeof payload.event_name !== 'string' || !payload.event_name) {
      throw new Error(
        'VueSnowplow#trackKaiaEvent:' +
          ' expected payload.event_name to be non-empty string,' +
          ` ${payload.event_name} got instead`,
      )
    }

    const data = {
      event: {
        schema: 'iglu:com.kaiahealth/kaia_event/jsonschema/1-0-0',
        data: payload,
      },
    }

    const context = []

    if (Object(options.custom_payload) === options.custom_payload) {
      context.push({
        schema: 'iglu:com.kaiahealth/custom_payload/jsonschema/1-0-0',
        data: options.custom_payload,
      })
    }

    if (context.length > 0) {
      data.context = context
    }

    getSnowplowTracker.then((snowplow) => {
      snowplow.trackSelfDescribingEvent(data)
    })
  },

  trackPageView(url) {
    getSnowplowTracker.then((snowplow) => {
      snowplow.setCustomUrl(url)
      snowplow.trackPageView()
    })
  },

  /**
   @param payload object, example:
   * {
   *   targetUrl: this.$route.path,
   *   elementId: 'string',
   *   elementClasses: ['string'],
   *   elementTarget: 'string',
   *   elementContent: 'string',
   * }
   */
  trackLinkClick(payload) {
    payload = Object(payload) === payload ? payload : {}
    getSnowplowTracker.then((snowplow) => {
      snowplow.trackLinkClick(payload)
    })
  },

  enableTracking() {
    // for tracking we need cookieConsent and trackingConsent to be given
    let cookieConsentAccepted = null
    try {
      cookieConsentAccepted = new Event('cookieConsentAccepted')
    } catch {
      cookieConsentAccepted = document.createEvent('Event')
      cookieConsentAccepted.initEvent('cookieConsentAccepted', false, false)
    }
    document.dispatchEvent(cookieConsentAccepted)

    let trackingAccepted = null
    try {
      trackingAccepted = new Event('trackingAccepted')
    } catch {
      trackingAccepted = document.createEvent('Event')
      trackingAccepted.initEvent('trackingAccepted', false, false)
    }

    document.dispatchEvent(trackingAccepted)
  },

  /**
   * Initialize DataDog for RUM (incl crash reporting) and log collection.
   * Proxy can be configured via environment variables to enable EU-compliant solution.
   */
  initDatadog() {
    const clientToken = env.VITE_DATADOG_CLIENT_TOKEN
    const applicationId = env.VITE_DATADOG_APP_ID
    if (applicationId == null || clientToken == null) return
    // Comment this out if you want DD to be enabled on localhost
    if (window.location.hostname === 'localhost') return

    const commonConfig = {
      clientToken: clientToken,
      site: 'datadoghq.com',
      service: `${env.VITE_DISEASE}-${env.VITE_GEO}-webcheckout`,
      env: env.VITE_MODE,
      version: env.VITE_VERSION,
      proxy: env.VITE_DATADOG_PROXY,
    }
    datadogRum.init({
      ...commonConfig,
      applicationId: applicationId,
      allowedTracingUrls: [
        // trace all kaia domains and subdomains but not the snowplow collector
        /https:\/\/(?!collector\.)(?:.*\.)?kaiahealth\.(de|com)/,
      ],
      trackUserInteractions: true,
      trackResources: !env.isProd,
      trackLongTasks: !env.isProd,
      telemetrySampleRate: 0,
      defaultPrivacyLevel: 'mask-user-input',
      sessionReplaySampleRate: env.isUs ? 100 : 0,
      beforeSend: (event) => {
        if (
          event.error?.resource?.url?.includes('snowplow') ||
          event.resource?.url?.includes('snowplow')
        )
          return false
      },
    })
    datadogLogs.init({
      ...commonConfig,
      telemetrySampleRate: 0,
      forwardConsoleLogs: ['log', 'info', 'warn', 'error'],
      forwardReports: ['intervention', 'deprecation'], // filter out csp_violations
      forwardErrorsToLogs: true,
    })
  },
}
