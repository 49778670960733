<template>
  <div class="slider-view">
    <div class="score-slider">
      <img
        v-if="type === 'red'"
        ref="sliderScale"
        alt="slider"
        class="score-slider__scale"
        src="@shared/assets/img/score-slider-scale_red.svg"
      />
      <img
        v-else-if="type === 'green'"
        ref="sliderScale"
        alt="slider"
        class="score-slider__scale"
        src="@shared/assets/img/score-slider-scale_green.svg"
      />
      <button
        ref="sliderKnob"
        type="button"
        role="button"
        aria-label="slider knob"
        class="score-slider__knob score-slider__knob--hidden"
        :style="`left:${knobLeftPosition}%; width:${sliderKnobWidth}px;`"
        :data-qa-output="dataQAOutput"
        :data-qa-input="dataQAInput"
        :data-qa="dataQA"
      >
        <img
          ref="sliderKnobImage"
          alt="slider knob"
          class="score-slider__knob-img score-slider__knob-img--none"
          src="@shared/assets/img/score-slider-knob.svg"
        />
        <span
          class="score-slider__label"
          v-html="sliderLabel"
        />
      </button>
    </div>
    <div class="hints">
      <div
        class="slider-view__hint"
        v-html="minText"
      />
      <div
        class="slider-view__hint"
        v-html="maxText"
      />
    </div>
  </div>
</template>

<script>
export default {
  name: 'ScoreSlider',
  props: {
    maxScore: {
      type: Number,
      default: 0,
    },
    score: {
      type: Number,
      default: 0,
    },
    label: {
      type: String,
      default: '',
    },
    dataQA: {
      type: String,
      default: '',
    },
    type: {
      type: String,
      default: 'red',
    },
    minText: {
      type: String,
      default: '',
    },
    maxText: {
      type: String,
      default: '',
    },
  },
  emits: ['tap', 'change'],
  data() {
    return {
      sliderLabel: '',
      sliderWidth: 0,
      knobLeftPosition: 0,
      previousKnobLeftPosition: 0,
      startKnobLeftPosition: 0,
      knobElementWidth: 0,
      sliderKnobWidth: 0,
      startPageX: 0,
      dataQAOutput: 0,
      dataQAInput: 0,
      dataQAInputObserver: null,
    }
  },
  computed: {
    getSafeScore() {
      return (this.score != null && this.score >= 0 && this.score) || 0
    },
    getSafeMaxScore() {
      return (this.maxScore != null && this.maxScore >= 0 && this.maxScore) || 0
    },
    getScoreSize() {
      return this.getSafeMaxScore > 0
        ? (100 - this.knobElementWidth) / this.getSafeMaxScore
        : (100 - this.knobElementWidth) / 100
    },
  },
  created() {
    this.sliderLabel = this.label || '?'
  },
  mounted() {
    const sliderScaleLoadHandler = () => {
      this.sliderWidth = this.$el.offsetWidth

      this.dataQAOutput = this.getSafeScore
      this.dataQAInput = this.getSafeScore

      this.$refs.sliderKnob.classList.remove('score-slider__knob--hidden')
      this.$refs.sliderKnobImage.classList.remove(
        'score-slider__knob-img--none',
      )

      this.knobElementWidth = Math.floor(
        (this.$refs.sliderKnobImage.offsetWidth / this.sliderWidth) * 100,
      )
      this.knobLeftPosition = this.getScoreSize * this.getSafeScore

      // Somehow we need this for FF browser;
      this.sliderKnobWidth = this.$refs.sliderKnobImage.offsetWidth

      this.dataQAInputObserver = new MutationObserver((mutations) => {
        mutations.forEach(this.handledataQAInputMutation)
      })
      this.dataQAInputObserver.observe(this.$refs.sliderKnob, {
        attributes: true,
      })
    }

    this.$refs.sliderScale.addEventListener(
      'mousedown',
      this.sliderScaleStartHandler,
    )
    this.$refs.sliderScale.addEventListener(
      'touchstart',
      this.sliderScaleStartHandler,
    )

    this.$refs.sliderKnob.addEventListener(
      'touchmove',
      this.sliderKnobMoveHandler,
    )

    this.$el.addEventListener('mousedown', this.slideStartHandler)
    this.$el.addEventListener('touchstart', this.slideStartHandler)

    this.$refs.sliderScale.onload = () => {
      this.$refs.sliderScale.onload = null
      if (this.$refs.sliderKnobImage.onload == null) {
        sliderScaleLoadHandler()
      }
    }

    this.$refs.sliderKnobImage.onload = () => {
      this.$refs.sliderKnobImage.onload = null
      if (this.$refs.sliderScale.onload == null) {
        sliderScaleLoadHandler()
      }
    }
  },
  beforeUnmount() {
    this.$refs.sliderScale.removeEventListener(
      'mousedown',
      this.sliderScaleStartHandler,
    )
    this.$refs.sliderScale.removeEventListener(
      'touchstart',
      this.sliderScaleStartHandler,
    )

    this.$refs.sliderKnob.removeEventListener(
      'touchmove',
      this.sliderKnobMoveHandler,
    )

    this.$el.removeEventListener('mousedown', this.slideStartHandler)
    this.$el.removeEventListener('touchstart', this.slideStartHandler)

    this.dataQAInputObserver.disconnect()
  },
  methods: {
    slideStartHandler(e) {
      if (e.target !== this.$refs.sliderKnobImage) {
        return
      }

      e.preventDefault()

      this.sliderLabel = this.dataQAOutput
      this.$emit('tap', {
        value: this.dataQAOutput,
      })

      this.sliderWidth = this.$el.offsetWidth
      this.knobElementWidth = Math.floor(
        (e.target.offsetWidth / this.sliderWidth) * 100,
      )
      this.startPageX = e.pageX || e.touches[0].pageX
      this.startKnobLeftPosition = this.knobLeftPosition
      this.previousKnobLeftPosition = this.knobLeftPosition

      document.addEventListener('mousemove', this.slideMoveHandler)
      document.addEventListener('touchmove', this.slideMoveHandler)

      document.addEventListener('mouseup', this.slideEndHandler)
      document.addEventListener('touchend', this.slideEndHandler)
    },
    slideMoveHandler(e) {
      const currentPageX = e.pageX || e.touches[0].pageX
      const deltaX =
        Math.abs((currentPageX - this.startPageX) / this.sliderWidth) * 100

      if (this.previousKnobLeftPosition !== this.knobLeftPosition) {
        this.previousKnobLeftPosition = this.knobLeftPosition

        this.dataQAOutput = Math.ceil(this.knobLeftPosition / this.getScoreSize)
        this.$emit('change', {
          value: this.dataQAOutput,
        })
        this.sliderLabel = this.dataQAOutput
      }

      if (currentPageX > this.startPageX) {
        const nextKnobLeftPosition =
          (this.startKnobLeftPosition + this.adjustDeltaX(deltaX)).toFixed(2) *
          1

        this.knobLeftPosition =
          nextKnobLeftPosition >= 100 - this.knobElementWidth
            ? 100 - this.knobElementWidth
            : nextKnobLeftPosition
      } else if (currentPageX < this.startPageX) {
        const nextKnobLeftPosition =
          (this.startKnobLeftPosition - this.adjustDeltaX(deltaX)).toFixed(2) *
          1

        this.knobLeftPosition =
          nextKnobLeftPosition <= 0 ? 0 : nextKnobLeftPosition
      }
    },
    slideEndHandler() {
      if (this.previousKnobLeftPosition !== this.knobLeftPosition) {
        this.previousKnobLeftPosition = this.knobLeftPosition

        this.dataQAOutput = Math.ceil(this.knobLeftPosition / this.getScoreSize)
        this.$emit('change', {
          value: this.dataQAOutput,
        })
        this.sliderLabel = this.dataQAOutput
      }

      document.removeEventListener('mousemove', this.slideMoveHandler)
      document.removeEventListener('touchmove', this.slideMoveHandler)

      document.removeEventListener('mouseup', this.slideEndHandler)
      document.removeEventListener('touchend', this.slideEndHandler)
    },
    sliderScaleStartHandler(e) {
      e.preventDefault()
    },
    sliderKnobMoveHandler(e) {
      e.preventDefault()
    },
    adjustDeltaX(deltaX) {
      const scoreSize = this.getScoreSize
      if (deltaX > scoreSize) {
        return Math.floor(deltaX / scoreSize) * scoreSize
      }

      return 0
    },
    handledataQAInputMutation(mutation) {
      if (
        mutation.type !== 'attributes' ||
        mutation.attributeName !== 'data-qa-input'
      ) {
        return
      }

      const nextScoreValue =
        mutation.target.getAttribute(mutation.attributeName) * 1
      let currentScoreValue = this.dataQAOutput * 1

      if (
        nextScoreValue < 0 ||
        nextScoreValue > this.maxScore ||
        nextScoreValue === currentScoreValue
      ) {
        return
      }

      const clientRect = mutation.target.getBoundingClientRect()
      let clientRectX = clientRect.x

      this.$refs.sliderKnobImage.dispatchEvent(
        new MouseEvent('mousedown', {
          bubbles: true,
          clientX: clientRectX,
          clientY: clientRect.y,
        }),
      )

      while (currentScoreValue !== nextScoreValue) {
        clientRectX =
          currentScoreValue > nextScoreValue ? clientRectX - 5 : clientRectX + 5

        this.$refs.sliderKnobImage.dispatchEvent(
          new MouseEvent('mousemove', {
            bubbles: true,
            clientX: clientRectX,
            clientY: clientRect.y,
          }),
        )

        currentScoreValue = this.dataQAOutput
      }

      this.$refs.sliderKnobImage.dispatchEvent(
        new MouseEvent('mouseup', {
          bubbles: true,
          clientX: clientRectX,
          clientY: clientRect.y,
        }),
      )
    },
  },
}
</script>

<style lang="scss">
.slider-view {
  margin: 0 auto;
  width: 80%;
  padding: 100px 0 48px 0;

  @media (max-width: $breakpoint-mobile) {
    width: 100%;
    padding: 68px 0 16px 0;
  }
}

.score-slider {
  width: 100%;
  min-height: 26px;
  position: relative;

  &__scale {
    width: 100%;
    display: block;
  }

  &__label {
    position: absolute;
    left: 50%;
    top: -52px;
    transform: translateX(-50%);
    padding: 0 8px;
    background-color: $white;
    box-shadow: 0 4px 8px rgba(28, 62, 126, 0.2);
    border-radius: 8px;
    color: $midnight;
    text-align: center;

    @include kaia-typography-h5($font-weight-semi-bold);
  }

  &__knob {
    position: absolute;
    top: 0;
    bottom: 0;
    box-sizing: border-box;
    background-color: transparent;
    border: none;
    padding: 0;
    border-radius: 50%;
    box-shadow:
      0 8px 8px rgba(10, 28, 64, 0.1),
      0 8px 16px rgba(10, 28, 64, 0.1);

    &--hidden {
      visibility: hidden;
    }

    &-img {
      cursor: pointer;
      height: 100%;

      &--none {
        display: none;
      }
    }
  }
}

.hints {
  display: flex;
  justify-content: space-between;
  margin-top: 16px;
  @include kaia-typography-p2($font-weight-normal);

  @media (max-width: $breakpoint-mobile) {
    @include kaia-typography-p1($font-weight-normal);
  }
}
</style>
