<template>
  <div class="b2b-download-link">
    <AppLogo />

    <h2 v-html="$t('download_page_left_title')" />

    <div class="plan">
      <div class="plan-item">
        <div class="image">
          <div class="workouts" />
        </div>

        <div
          class="text"
          v-html="$t('download_page_back_workouts')"
        />
      </div>

      <div class="plan-item">
        <div class="image">
          <div class="relaxation" />
        </div>

        <div
          class="text"
          v-html="$t('download_page_relaxation_practices')"
        />
      </div>

      <div class="plan-item">
        <div class="image">
          <div class="modules" />
        </div>

        <div
          class="text"
          v-html="$t('download_page_education_modules')"
        />
      </div>
    </div>
  </div>
</template>

<script>
import AppLogo from '@shared/components/AppLogo.vue'

export default {
  name: 'B2BTherapyOutlookMedia',
  components: {
    AppLogo,
  },
}
</script>

<style lang="scss" scoped>
.b2b-download-link {
  padding: 60px;
  min-height: 100dvh;
  background-color: $sky;

  h2 {
    text-align: center;
    font-size: 32px;
    line-height: 40px;
    font-weight: bold;
    margin: 100px 0 50px;
  }

  .plan {
    display: flex;
    justify-content: space-between;

    .plan-item {
      flex: 0 0 31%;
      border-radius: 16px;
      overflow: hidden;

      .image {
        position: relative;
        padding-bottom: 141%;

        div {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          background-size: cover;
          background-position: center;
          background-repeat: no-repeat;

          &.workouts {
            background-image: url('../assets/img/b2b/back-workouts.png');
          }

          &.relaxation {
            background-image: url('../assets/img/b2b/relaxation-practices.png');
          }

          &.modules {
            background-image: url('../assets/img/b2b/education-modules.png');
          }
        }
      }

      .text {
        background: white;
        height: 140px;
        box-sizing: border-box;
        padding: 16px;
        font-size: 16px;
        line-height: 23px;

        @media only screen and (max-width: 1280px) {
          height: 160px;
        }
      }
    }
  }
}
</style>
