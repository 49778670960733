import axios from 'axios'

import useAuthStore from '@shared/store/useAuthStore.js'
import env from '@shared/env.js'
import { datadogLogs } from '@datadog/browser-logs'

/**
 * Api instance for unauthenticated endpoints (and session creation):
 * - does not populate auth tokens into the headers
 * - pulls auth tokens from response headers if present
 * @type {AxiosInstance}
 */
const PublicApi = axios.create({
  baseURL: `${env.VITE_API_BASE_URL}`,
})

/**
 * Api instance for authenticated endpoints:
 * - populates auth tokens into request headers
 * - pulls auth tokens from response headers if present
 * - rotates auth tokens if required
 * @type {AxiosInstance}
 */
const PrivateApi = axios.create({
  baseURL: `${env.VITE_API_BASE_URL}`,
})

function getAuthHeadersFromStore() {
  const authStore = useAuthStore()
  return Object.fromEntries(
    [
      ['access-token', authStore.accessToken],
      ['token-type', authStore.tokenType],
      ['client', authStore.client],
      ['guid', authStore.guid],
    ].filter(([, value]) => value !== null),
  )
}

function saveAuthHeadersToStore(response) {
  const authStore = useAuthStore()
  const { headers } = response
  if (!('access-token' in headers)) return
  if (headers['access-token'] === authStore.accessToken) return

  authStore.accessToken = headers['access-token']
  authStore.tokenType = headers['token-type']
  authStore.client = headers.client
  authStore.guid = headers.guid
  authStore.refreshToken = headers['refresh-token'] || ''
  authStore.expiry = headers.expiry
}

function assembleClientHeaders() {
  const authStore = useAuthStore()
  const appId = `kaia-${env.VITE_DISEASE}-WebCheckout`
  const clientVersion = env.VITE_VERSION
  const userAgent = `${appId}-${clientVersion}`
  const datadogSessionId = datadogLogs.getInternalContext()?.session_id
  const defaultHostIdentifier = 'unset' // indicates that the WebCheckout app is not rendered in a webview

  return {
    'x-app-id': appId,
    'x-user-agent': userAgent,
    'x-client-version': clientVersion,
    'x-host-client-app-id': authStore.hostClientAppId || defaultHostIdentifier,
    'x-host-client-version':
      authStore.hostClientVersion || defaultHostIdentifier,
    'x-session-id': datadogSessionId,
  }
}

/**
 * Request a new token using the refresh token if the current token is expired.
 * The response interceptor from the PublicApi will store the new auth information
 * before the original request (on the PrivateApi) is resumed and pulls them from the store
 * @returns {Promise<AxiosResponse<any>>}
 */
// TODO rework to only call this when needed (aka 404 on private api) + remove tokens when it fails (logout) so that this store can be used to identify whether the user is logged in
function refreshTokenIfNeeded() {
  const authStore = useAuthStore()
  const today = new Date()
  if (
    authStore.refreshToken !== null &&
    today.getTime() >= authStore.expiry * 1000
  ) {
    return PublicApi.post('auth/refresh', null, {
      headers: {
        'refresh-token': authStore.refreshToken,
        client: authStore.client,
        guid: authStore.guid,
      },
    })
  }
}

function logError(error) {
  datadogLogs.logger.warn(
    error.message,
    {
      error: {
        request_url: error?.config?.url,
        request_method: error?.config?.method,
        response_error:
          error?.response?.data?.error ?? error?.response?.data?.errors?.[0],
        response_code_text: error?.code,
        response_code: error?.response?.status,
      },
    },
    error,
  )
}

function initPublicApi() {
  PublicApi.interceptors.request.use((config) => {
    config.headers = {
      ...config.headers,
      ...assembleClientHeaders(),
    }
    return config
  })

  PublicApi.interceptors.response.use(
    (response) => {
      saveAuthHeadersToStore(response)
      return response
    },
    (error) => {
      logError(error)
      return Promise.reject(error)
    },
  )
}

function initPrivateApi() {
  PrivateApi.interceptors.request.use(async (config) => {
    await refreshTokenIfNeeded()
    config.headers = {
      ...config.headers,
      ...getAuthHeadersFromStore(),
      ...assembleClientHeaders(),
    }
    return config
  })

  PrivateApi.interceptors.response.use(
    (response) => {
      saveAuthHeadersToStore(response)
      return response
    },
    (error) => {
      logError(error)
      return Promise.reject(error)
    },
  )
}

/**
 * Initialize the API layer
 * Needs to be called at the start of the Vue application (and after the auth store is initialized)
 */
export const initApi = () => {
  initPublicApi()
  initPrivateApi()
}

export { PublicApi, PrivateApi }
